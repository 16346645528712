import React from 'react';
import LazyLoadImage from "../LazyLoadImage";
import ProjectTitle from "./ProjectTitle";

class ImageLeftTitle extends React.Component {

    render() {
        return (
            <div className="flex flex-wrap justify-i-center align-i-center
                            width-100p mar-t-2p maxw440:mar-b-15p
                            border-5px-solid-DAE7E6
                            hover-bac-col-2C hover-opacity-0p6-notImp hover-bac-col-trans
                            maxw768:bac-col-2C">
                <div className="flex-basis-40 maxw660:flex-100p">
                    <LazyLoadImage src={this.props.src}/>
                </div>
                <div className="flex-basis-60 maxw660:flex-100p pad-4p">
                    <ProjectTitle year={this.props.year}
                                  title={this.props.title}
                                  subtitle={this.props.subtitle}/>
                </div>
            </div>
        );
    }
}

export default ImageLeftTitle;