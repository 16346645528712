
function DescBanner() {
    return (
        <div className="DescBanner z-20 pos-absolute
                        minw769:top-33vw minw769:left-30vw
                        minw820:left-29p5vw
                        minw962:top-31vw
                        minw1024:top-24p5vw minw1024:left-28vw
                        minw1080:top-24vw minw1024:left-29vw
                        minw1080:left-28vw
                        minw1180:top-24p5vw
                        minw1280:top-246px minw1280:left-325px
                        minw1366:top-268px minw1366:left-345px
                        minw2000:top-14vw
                        minw2256:top-12vw
                        minw2560:top-10p5vw
                        maxw768:top-54vw maxw565:top-60vw maxw480:top-74vw maxw440:top-88vw maxw375:top-88vw
                        maxw2560:width-64p maxw768:width-100p
                        pad-t-20px pad-b-20px pad-l-10px pad-r-5px
                        minw769:bor-rad-8px
                        maxm768:text-cen
                        maxw2560:font-size-2em maxw1024:font-size-1p5em maxw860:font-size-1p4em maxw768:font-size-1p5em
                        bac-col-2C color-white">
            <div className="bac-col-2C color-white">
                I'm a UX Researcher & Designer for SaaS & Enterprise.
            </div>
            <div className="bac-col-2C color-white">
                Based in Dublin, Ireland.
            </div>
        </div>
    );
}

export default DescBanner;