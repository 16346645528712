import HiCircleBackground from "../components/home/HiCircleBackground";
import DescBanner from "../components/home/DescBanner";
import Portfolio from "../components/portfolio/Portfolio";
import React from "react";

function Home() {
    return (
        <div className="Home overflow-x-clip">
            <div className="HomeAbove pos-relative">
                <HiCircleBackground/>
                <DescBanner/>
            </div>
            <Portfolio/>
        </div>
    );
}

export default Home;