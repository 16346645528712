import ProjectBackButton from "../../components/projects/ProjectBackButton";
import TopHeroImage from "../../components/projects/TopHeroImage";
import WholesalerHero from "../../assets/images/pwholesalers/WholesalerHero.png";
import HeadingProject from "../../components/projects/HeadingProject";
import OutlineRole from "../../components/projects/OutlineRole";
import ProjectInfo1 from "../../components/projects/ProjectInfo1";
import H1 from "../../components/projects/H1";
import NDAInfo from "../../components/projects/NDAInfo";

function ProjectWholesaler() {
    return (
        <div className="ProjectWholesaler pad-t-70px maxw640:pad-t-60px">
            <ProjectBackButton/>
            <TopHeroImage src={WholesalerHero}/>
            <div className="WholesalerHead">
                <HeadingProject heading="Invoicing System for a Food Wholesaler"/>
                <NDAInfo/>
                <OutlineRole outline=""
                             role=""/>
            </div>
            <div className="WholesalerBody">
                <H1 h1="Process"/>
                <div className="Stats
                                flex maxw640:flex-wrap align-i-center justify-i-center
                                pad-t-4p pad-b-4p
                                bac-col-white">
                    <ProjectInfo1 head="Agile" sub="Lifecycle"/>
                    <ProjectInfo1 head="Biweekly" sub="Sprints"/>
                    <ProjectInfo1 head="Qualitative" sub="Research"/>
                    <ProjectInfo1 head="Biweekly" sub="Testing"/>
                </div>
            </div>
        </div>
    );
}

export default ProjectWholesaler;