import LazyLoadImage from "../LazyLoadImage";
import LinkedInImage from '../../assets/images/aboutcontact/LinkedIn_Image.png';

function About() {
    return (
        <div className="About flex-basis-75 maxw768:flex-100p
                        pad-r-3p maxw440:pad-r-0px maxw768:pad-b-20px ">
            <div className="HeaderContainer flex maxw440:flex-wrap justify-i-left align-i-center">
                <div className="AboutImage
                                flex-basis-30 maxw440:flex-100p
                                maxw440:pad-40px">
                    <LazyLoadImage src={LinkedInImage} alt="Picture of Rachael"/>
                </div>
                <div className="AboutHeader
                                flex-basis-70 maxw440:flex-100p
                                pad-40px maxw440:pad-0px
                                font-size-2em maxw440:font-size-1p6em
                                color-1A3F45">
                    <div>Hi! I'm a UX Researcher and Designer for SaaS and Enterprise Software.</div>
                    <div>Based in Dublin, Ireland.</div>
                </div>
            </div>
            <div className="AboutInfo
                            maxw440:flex-100p pad-t-5p font-size-1p2em font-fam-con-light font-weight-500">
                <div>
                    Since 2013, I have worked within the SaaS and Enterprise Software space, in different
                    facets that have honed my expertise in user experience and service design. Initially I
                    started out as a developer, often involved with requirements gathering and UI, with
                    my career ultimately evolving back fully to my first love, design!
                </div>
                <br/>
                <div>
                    Throughout my design career, I have championed user-centered design principles, leveraging
                    user research, both qualitative and quantitative, and data-driven insights to inform my design
                    decisions. As a Senior UX Designer, I have successfully shaped design strategy, and fostered
                    a culture of innovation and collaboration. I'm a strong
                    proponent of the Lean UX approach and have experience integrating Lean UX practices and
                    processes into the Agile development lifecycle. I'm very passionate about what I do and ensure
                    to keep up-to-date with industry trends, emerging technologies, and best practices.
                </div>
                <br/>
                <div>
                    I have a B.Sc. in Product Design, a H.Dip. in Software Development, and a M.Sc. in UX Design,
                    for which I graduated student of the year.
                </div>
            </div>
        </div>
    );
}

export default About;