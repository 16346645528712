import React from "react";

class EndProject extends React.Component {
    render() {
        return (
            <div className="EndProject">
                <div className="End text-al-center">
                    <p>End.</p>
                </div>
                <br/>
                <div className="ColourLine flex height-15px width-100p">
                    <div className="Col1 width-6p25p bac-col-EDE8EA"></div>
                    <div className="Col2 width-6p25p bac-col-E6EFEE"></div>
                    <div className="Col3 width-6p25p bac-col-FAFAF2"></div>
                    <div className="Col4 width-6p25p bac-col-E9ECEA"></div>
                    <div className="Col1 width-6p25p  bac-col-EDE8EA"></div>
                    <div className="Col2 width-6p25p  bac-col-E6EFEE"></div>
                    <div className="Col3 width-6p25p  bac-col-FAFAF2"></div>
                    <div className="Col4 width-6p25p  bac-col-E9ECEA"></div>
                    <div className="Col1 width-6p25p bac-col-EDE8EA"></div>
                    <div className="Col2 width-6p25p bac-col-E6EFEE"></div>
                    <div className="Col3 width-6p25p bac-col-FAFAF2"></div>
                    <div className="Col4 width-6p25p bac-col-E9ECEA"></div>
                    <div className="Col1 width-6p25p  bac-col-EDE8EA"></div>
                    <div className="Col2 width-6p25p  bac-col-E6EFEE"></div>
                    <div className="Col3 width-6p25p  bac-col-FAFAF2"></div>
                    <div className="Col4 width-6p25p  bac-col-E9ECEA"></div>
                </div>
            </div>
        );
    }
}

export default EndProject;