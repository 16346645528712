import ProjectBackButton from "../../components/projects/ProjectBackButton";
import TopHeroImage from "../../components/projects/TopHeroImage";
import GovHero from "../../assets/images/pgov/GovHero.png";
import HeadingProject from "../../components/projects/HeadingProject";
import OutlineRole from "../../components/projects/OutlineRole";
import H1 from "../../components/projects/H1";
import NDAInfo from "../../components/projects/NDAInfo";
import ProjectInfo2 from "../../components/projects/ProjectInfo2";
import ProjectInfo3 from "../../components/projects/ProjectInfo3";
import ProjectStats1 from "../../components/projects/ProjectStats1";
import ProjectStats2 from "../../components/projects/ProjectStats2";
import ProjectStats3 from "../../components/projects/ProjectStats3";
import EndProject from "../../components/projects/EndProject";
import React from "react";
import H2andParagraph from "../../components/projects/H2andParagraph";
import Clicks from "../../assets/images/pgov2/Click.png";
import Time from "../../assets/images/pgov2/Time.png";
import Testing from "../../assets/images/pgov2/Testing.png";
import Interview from "../../assets/images/pgov2/Interview.png";
import LazyLoadImage from "../../components/LazyLoadImage";
import Results from "../../assets/images/pgov2/Results.png";
import Results2 from "../../assets/images/pgov2/Results2.png";
import CvB from "../../assets/images/pgov2/CvB.png";
import H2 from "../../components/projects/H2";
import H3 from "../../components/projects/H3";


function ProjectGov2() {
    return (
        <div className="ProjectGov2 pad-t-70px maxw640:pad-t-60px">
            <ProjectBackButton/>
            <TopHeroImage src={GovHero}/>
            <div className="Gov2Head">
                <HeadingProject heading="Efficiency Benchmarking for Public Body 1"/>
                <NDAInfo/>
                <OutlineRole outline="This is 1 of 3 efficiency benchmarking studies, undertaken to quantify the
                                      value of conducting UX research. The organisation was planning to
                                      undertake a significant project modernising numerous legacy systems,
                                      though with minimal user input and using problematic methods. I discussed
                                      the benefits of UX, it's best practices and process, with the project
                                      coordinators, who then realised they were missing the UX skillset on their
                                      team. In order to request more personnel, they needed to be able to clearly
                                      establish the benefits of doing so. I suggested this study and took
                                      it upon myself to deliver a quantified example of the benefits specific
                                      to the organisation's systems."
                             role="I recommended, organised and conducted this study. Based on it's results,
                                   UX was seen as an essential discipline to include in the project team's
                                   skillset, and process to incorporate into the development lifecycle."/>
            </div>
            <div className="StatsGov3 flex maxw640:flex-wrap align-i-center justify-i-center">
                <ProjectStats1 number="1" name="User group"/>
                <ProjectStats2 number="7" name="Users"/>
                <ProjectStats3 number="1" name="User journey"/>
            </div>
            <div className="Gov2Body">
                <H1 h1="Process"/>
                <div className="StatsGov2 flex maxw640:flex-wrap align-i-center justify-i-center bac-col-white">
                    <ProjectInfo3 head="Quantitative" sub="Research"/>
                    <ProjectInfo2 head="Qualitative" sub="Research"/>
                </div>
                <div className="QQMethods flex maxw640:flex-wrap align-i-center justify-i-center
                                pad-l-8p pad-r-8p pad-t-4p pad-b-4p
                                text-al-center font-size-1p5em bac-col-E9ECEA">
                    <div className="flex-basis-40 maxw640:flex-100p
                                    height-40p pad-3p">
                        <img className="mar-auto" src={Testing} alt="Usability testing icon" width="40%"/>
                        <div className="pad-20px">Usability Test</div>
                    </div>
                    <div className="flex-basis-20 maxw640:flex-100p
                                    maxw640:pad-tb-10p">
                        <div>followed by</div>
                    </div>
                    <div className="flex-basis-40 maxw640:flex-100p height-40p pad-3p">
                        <img className="mar-auto" src={Interview} alt="User interview icon" width="40%"/>
                        <div className="pad-20px">User Interview</div>
                    </div>
                </div>
                <H2 h2="Quantitative Research"/>
                <H2andParagraph header="UX Benchmarking"
                                text="UX Benchmarking is the practice of recording quantifiable metrics and
                                then comparing the metrics across system versions to assess it's relevant
                                performance against a meaningful standard.
                                First a baseline metric is established, and, when changes are made, further
                                metrics are recorded using the same methodology. The metrics are compared to
                                establish the level of improvement achieved."/>
                <div className="StatsGov3 flex maxw640:flex-wrap align-i-center justify-i-center
                                text-al-center bac-col-FAFAF2">
                    <div className="flex-basis-40 maxw640:flex-100p maxw640:pad-tb-8p-10p">
                        <div className="ProStats
                                        width-100p
                                        bac-col-FAFAF2">
                            <div className="StatQty text-al-center font-weight-700 font-size-3em color-1A3F45">
                                2
                            </div>
                            <div className="StatName text-al-center font-size-1p5em">
                                Metrics
                            </div>
                        </div>
                    </div>
                    <div className="flex-basis-30 maxw640:flex-100p maxw640:pad-tb-8p-10p height-40p pad-3p bac-col-EDE8EA">
                        <img className="mar-auto" src={Clicks} alt="Mouse arrow clicking" width="40%"/>
                        <div className="color-1A3F4"><h2>EFFORT</h2></div>
                        <div>No. of Clicks</div>
                    </div>
                    <div className="flex-basis-30  maxw640:flex-100p maxw640:pad-tb-8p-10p height-40p pad-3p bac-col-E6EFEE">
                        <img className="mar-auto" src={Time} alt="Stop watch" width="40%"/>
                        <div><h2>TIME</h2></div>
                        <div>On Task</div>
                    </div>
                </div>
                <div className="EffMetrics
                                pad-l-8p pad-r-8p pad-t-4p pad-b-4p
                                maxw640:pad-tb-10p
                                bac-col-E9ECEA">
                    <h2 className="pad-b-2p">Efficiency Metrics</h2>
                    <p>
                        Efficiency metrics are especially relevant to enterprise software and
                        also worked best for this study. The users were the organisation's own employees,
                        and the user journey assessed saw them interact with two internal systems. Saving
                        employee time directly translates to money saved, which is something that would
                        easily resonate with the study's audience. So, while I also focused on improving
                        the broader aspects of usability, and UX, along the user's journey, metrics for speed
                        of use were what I benchmarked.
                    </p>
                    <br/>
                    <p>
                        The number of clicks, to complete a user flow, were recorded. And,
                        time on task, for example, a click or viewing an image, was calculated for each step
                        of a user flow and was recorded in milliseconds for this study. When considered
                        for efficiency purposes, the hope would be for the number of clicks and overall time
                        to be as low as possible. Bearing in mind that for each of these metrics there is a
                        limit to how low either can go whilst retaining a good user experience overall.
                    </p>
                </div>
                <br/>
                <H2 h2="Study Results"/>
                <H3 h3="Average steps and time recorded"/>
                <div className="Results1Image
                                mar-r-8p mar-l-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={Results}/>
                </div>
                <br/>
                <br/>
                <H3 h3="Potential time saved over 7 month period"/>
                <div className="Results2Image
                                mar-r-8p mar-l-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={Results2}/>
                </div>
                <br/>
                <br/>
                <H3 h3="The cost of implementing the redesign compared to the benefits"/>
                <div className="CvBImage
                                mar-r-8p mar-l-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={CvB}/>
                </div>
                <br/>
                <br/>
                <br/>
                <div className="bac-col-white">
                    <H2 h2="Qualitative  Research"/>
                    <div className="pad-l-8p pad-r-8p pad-b-4p">
                        <p>
                            Each usability test was followed by a semi-structured user interview for further
                            insight into the user's experience.
                        </p>
                    </div>
                </div>
                <div className="bac-col-E9ECEA">
                    <H2 h2="Conclusions"/>
                    <div className="pad-l-8p pad-r-8p pad-b-4p">
                        <p>
                            The usability tests revealed that the user flows were over complicated, with much
                            potential for simplification. They also uncovered that the majority of time spent was
                            on viewing images and configuration.
                        </p>
                        <br/>
                        <p>
                            The user interviews revealed that the system did not allow for zooming of images and
                            workarounds were needed which significantly increased the time spent viewing an image.
                            It was also established that there were unnecessary steps required during the
                            configuration which could be removed.
                        </p>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <EndProject />
            </div>
        </div>
    );
}

export default ProjectGov2;