import ProjectBackButton from "../../components/projects/ProjectBackButton";
import TopHeroImage from "../../components/projects/TopHeroImage";
import GovHero from "../../assets/images/pgov/GovHero.png";
import HeadingProject from "../../components/projects/HeadingProject";
import OutlineRole from "../../components/projects/OutlineRole";
import ProjectInfo1 from "../../components/projects/ProjectInfo1";
import H1 from "../../components/projects/H1";
import NDAInfo from "../../components/projects/NDAInfo";
import ProjectStats1 from "../../components/projects/ProjectStats1";
import ProjectStats3 from "../../components/projects/ProjectStats3";
import ProjectStats2 from "../../components/projects/ProjectStats2";
import ProjectInfo4 from "../../components/projects/ProjectInfo4";
import ProjectInfo3 from "../../components/projects/ProjectInfo3";
import ProjectInfo2 from "../../components/projects/ProjectInfo2";
import H2 from "../../components/projects/H2";
import Phases from "../../assets/images/pgov3/Phases.png";
import Project2Phases from "../../assets/images/pgov3/Project2Phases.png";
import DesignSprint from "../../assets/images/pgov3/DesignSprint.png";
import BuildEx from "../../assets/images/pgov3/Build_Expression.png";
import InfoArch from "../../assets/images/pgov3/Information_Architecture.png";
import Sprint0 from "../../assets/images/pgov3/Sprint0.png";
import RunSim from "../../assets/images/pgov3/RunSim.png";
import RuleHis from "../../assets/images/pgov3/Flow1.png";
import Paper1 from "../../assets/images/pgov3/Paper_1.jpg";
import Paper2 from "../../assets/images/pgov3/Paper_2.jpg";
import Digital1 from "../../assets/images/pgov3/Digital1.jpg";
import Digital2 from "../../assets/images/pgov3/Digital2.png";
import HF1 from "../../assets/images/pgov3/HF_1.png";
import HF2 from "../../assets/images/pgov3/HF_2.png";
import RuleHis2 from "../../assets/images/pgov3/Rule_History.png";
import Persona from "../../assets/images/pgov3/Persona.png";
import UserJourney from "../../assets/images/pgov3/UserJourney.png";
import H3 from "../../components/projects/H3";
import H2andParagraph from "../../components/projects/H2andParagraph";
import LazyLoadImage from "../../components/LazyLoadImage";
import React from "react";
import TwoImages from "../../components/projects/TwoImages";
import EndProject from "../../components/projects/EndProject";
import ProblemSolution from "../../components/projects/ProblemSolution";
import {Link} from "react-router-dom";

function ProjectGov3() {
    return (
        <div className="ProjectGov3 pad-t-70px maxw640:pad-t-60px">
            <ProjectBackButton/>
            <TopHeroImage src={GovHero}/>
            <div className="Gov3Head">
                <HeadingProject heading="Compliance Rule Engine Management System for Public Body 1"/>
                <NDAInfo/>
                <OutlineRole outline="Design of the UX/UI for a management system sitting on top of
                                      a compliance rule engine. The rule engine is used to identify users
                                      with specific unwanted behaviours, while
                                      interacting with the organisation's online public facing systems,
                                      allowing for preventative measures to be taken.
                                      The capability to create and combine complex rules and rule sets was
                                      a requirement, also simulating a rule(s) check against a user(s) or
                                      user group(s), as well as keeping record of users and user groups
                                      that triggered (broke) a rule(s) and related statistical information."
                             role="I was lead and sole UX researcher and designer for this project, working along with
                                   1 BA, 7 developers and 2 QA. I planned, organised, and facilitated all design related meetings,
                                   workshops and collaborations, strategising UX essentials and timelines
                                   with the Project Owner and Scrum Master."/>
                <div className="StatsGov3 flex maxw640:flex-wrap align-i-center justify-i-center">
                    <ProjectStats1 number="1" name="User group"/>
                    <ProjectStats2 number="5" name="Users"/>
                    <ProjectStats3 number="28" name="User flows"/>
                </div>
            </div>
            <br/>
            <div className="Gov3Body">
                <H1 h1="Process"/>
                <div className="InfoGov3 flex maxw640:flex-wrap align-i-center justify-i-center">
                    <ProjectInfo1 head="Lean UX" sub="& Agile"/>
                    <ProjectInfo2 head="Biweekly" sub="Sprints"/>
                    <ProjectInfo3 head="Qualitative" sub="Research"/>
                    <ProjectInfo4 head="Weekly" sub="Testing"/>
                </div>
                <H2andParagraph header="Discovery" text="Before the project began, there was a 2 week discovery
                                                         phase where initial research on business goals, ux goals
                                                         and users was conducted. This enabled better project planning
                                                         and prepared design for subsequent phases."/>
                <div className="Goals flex maxw640:flex-wrap justify-i-center
                                maxw640:pad-tb-8p-10p">
                    <div className="BusinessGoals width-40p pad-l-8p pad-r-4p pad-t-4p pad-b-4p
                                    maxw640:flex-100p bac-col-EDE8EA">
                        <h2>Business Goals</h2>
                        <ul>
                            <li>Catch and prevent specific non-compliant customer behaviours when using
                                the organisations' SaaS software</li>
                            <li>Gain significant monetary savings as a result of more compliant customer
                                behaviour</li>
                        </ul>
                    </div>
                    <div className="UXGoals pad-l-4p width-30p pad-t-4p pad-b-4p maxw640:flex-100p bac-col-FAFAF2">
                        <h2>UX Goals</h2>
                        <ul>
                            <li>Task efficiency</li>
                            <li>Intuitive design</li>
                            <li>Easy to learn and use</li>
                        </ul>
                    </div>
                    <div className="UserNeeds pad-l-4p pad-r-8p width-30p pad-t-4p pad-b-4p maxw640:flex-100p bac-col-E9ECEA">
                        <h2>User Needs</h2>
                        <ul>
                            <li>To create and edit rules</li>
                            <li>To simulate activating rules</li>
                        </ul>
                    </div>
                </div>
                <div className="LeanUX width-100p text-al-left pad-t-4p maxw640:pad-tb-8p-4p
                                pad-l-8p pad-r-8p pad-b-2p bac-col-E6EFEE">
                    <h2>Lean UX Project Plan with 2 Phases</h2>
                </div>
                <div className="ProjectPlan2Phases
                                pad-r-16p pad-l-16p
                                bac-col-E6EFEE">
                    <br/>
                    <br/>
                    <img src={Project2Phases}/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                <H2 h2="Project Phase Details"/>
                <div className="ProPhasDetail flex align-i-center pad-l-4p pad-r-4p pad-t-4p pad-b-4p bac-col-white">
                    <div className="pad-2p"><h4>Dual-track Agile UX with Sprint Ahead method</h4><LazyLoadImage src={Phases}/></div>
                    <div className="pad-2p"><h4>Sprint 0</h4><LazyLoadImage src={Sprint0}/></div>
                    <div className="pad-2p"><h4>Design Sprint</h4><img src={DesignSprint}/></div>
                </div>
                <br/>
                {/* <H3 h3="Phases Example"/>
                <div className="PhasesImage
                                mar-r-8p mar-l-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={Phases}/>
                </div>
                <br/>
                <br/>
                <H3 h3="Sprint 0 Example"/>
                <div className="Sprint0Image
                                mar-r-8p mar-l-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={Sprint0}/>
                </div>
                <br/>
                <br/>
                <H3 h3="Design Sprint Example"/>
                <div className="DesignSprint
                                mar-r-8p mar-l-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <img src={DesignSprint}/>
                </div>*/}
                <H2 h2="Design Artifacts"/>
                <H3 h3="As Is User Journey Example"/>
                <div className="UserJourneyImage
                                mar-r-8p mar-l-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={UserJourney}/>
                </div>
                <br/>
                <br/>
                <br/>
                <H3 h3="Persona Example"/>
                <div className="PersonaImage
                                mar-r-8p mar-l-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={Persona}/>
                </div>
                <br/>
                <br/>
                <br/>
                <H3 h3="Information Architecture"/>
                <div className="IAImage
                                width-40p
                                mar-auto pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={InfoArch}/>
                </div>
                <br/>
                <br/>
                <br/>
                <H3 h3="User Flow Examples"/>
                <h4 className="pad-l-8p pad-r-8p pad-b-2p">Run a Simulation</h4>
                <div className="RunSimImage
                                mar-l-8p mar-r-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={RunSim}/>
                </div>
                <br/>
                <br/>
                <br/>
                <h4 className="pad-l-8p pad-r-8p pad-b-2p">View the History for a Rule</h4>
                <div className="RuleHisImage
                                mar-l-8p mar-r-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={RuleHis}/>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <H2 h2="Low Fidelity Prototype Examples"/>
                <H3 h3="Paper"/>
                <div className="PaperPImage">
                    <TwoImages src1={Paper1}
                               src2={Paper2}/>
                </div>
                <br/>
                <br/>
                <H3 h3="Digital"/>
                <div className="DigitalPImage">
                    <TwoImages src1={Digital1}
                               src2={Digital2}/>
                </div>
                <br/>
                <H2 h2="High Fidelity Prototype Examples"/>
                <div className="HighFidelityImage">
                    <TwoImages src1={HF1}
                               src2={HF2}/>
                </div>
                <br/>
                <br/>
                <H2 h2="Phase 1 Problem and Solution"/>
                <div className="ProblemSolution"  id="problem">
                    <div className="Problem
                                flex maxw640:flex-wrap
                                border-1px-solid-EDE8EA
                                bac-col-EDE8EA
                                align-i-center">
                        <div className="ProblemHeading
                                    flex-basis-40 maxw640:flex-100p
                                    maxw640:pad-4p
                                    text-al-center">
                            <h2>Problem</h2>
                        </div>
                        <div className="ProblemText
                                    flex-basis-60 maxw640:flex-100p
                                    pad-t-2p pad-b-2p pad-l-4p pad-r-8p
                                    maxw640:pad-l-8p maxw640:pad-tb-6p
                                    bac-col-white">
                            <p>Users needed to be able to join 2 or more rule templates to create a
                                single rule in some instances. Joining 3 or more rule templates
                                significantly increased the complexity of the join and required some
                                coding knowledge to complete. As the users were all non-technical, even
                                joining 2 rule templates may have posed a challenge, so this opened
                                the research question:</p>
                            <br/>
                            <p><b>How can we enable nontechnical users to learn
                                to create complex combination rules from the UI?</b></p>
                        </div>
                    </div>
                    <div className="Solution
                                flex maxw640:flex-wrap
                                border-1px-solid-E6EFEE
                                bac-col-E6EFEE
                                align-i-center">
                        <div className="SolutionText
                                    flex-basis-60 maxw640:flex-100p
                                    pad-t-2p pad-b-2p pad-l-8p pad-r-8p
                                    maxw640:pad-l-8p maxw640:pad-tb-6p
                                    bac-col-white">
                            <p>Hypothesis:</p>
                            <p><b>Providing guidelines and examples will help users to learn
                                how to create complex combination rules and increase success rate.</b></p>
                            <br/>
                            <Link className="color-blue-link text-underline" to={`/PublicBody1System/ExperimentDetails`}>
                                Go to problem & experiment details</Link>
                            <br/>
                            <br/>
                            <p>Following an experiment to test the hypothesis, it was concluded that
                                guidelines and examples were useful for users when learning to
                                join 2 or 3 rule templates, though they still had significant
                                issues when attempting to join 4 or more. Based on this, we collectively
                                decided that any joins of
                                4 or more rule templates should be done by a developer. While the users
                                would not be able to do this themselves, their main pain point was the
                                time it previously took to create or edit a rule. This could still be
                                significantly reduced by enabling developers to combine templates
                                through the UI, without having to be booked for the job or wait for
                                production code release.</p>
                        </div>
                        <div className="SolutionHeading
                                    flex-basis-40 maxw640:flex-100p
                                    maxw640:pad-4p
                                    text-al-center">
                            <h2>Solution</h2>
                        </div>
                    </div>
                </div>
                <div className="BuildExImage
                                pad-l-8p pad-r-8p pad-2p
                                bac-col-white overflow-hid">
                    <div className="border-5px-solid-E9ECEA">
                        <LazyLoadImage src={BuildEx}/>
                    </div>
                </div>
                <H2 h2="Phase 2 Problem and Solution"/>
                <ProblemSolution problem="Users needed the rules to have version
                                          control, making it possible to roll back to a previous
                                          version of the rule. They also required a rule to be both
                                          active (turned on), and hence it's data static, and
                                          the same rule to be editable, at the same time. Multiple historic
                                          versions of a rule, as well as current static and editable versions,
                                          posed an issue for both development and UX. How could these versions
                                          be stored? And, how could the UI enable users to how could this be visually displayed, retaining ease
                                          of use?"
                                 solution="To answer these questions, I first organised and facilitated a
                                          design workshop to collaborate with the project business team, BA and
                                          developers. Once we all agreed on the best way to store the
                                          information, I set up and facilitated a further design workshop with
                                          the users where we discussed ways to visually implement the technical
                                          solution, as well as, the best language to use. I then created mock-ups
                                          and fine-tuned them with users in another design workshop."/>
                <div className="RuleHistoryImage
                                pad-l-8p pad-r-8p pad-2p
                                bac-col-white overflow-hid">
                    <div className="border-5px-solid-E9ECEA">
                        <LazyLoadImage src={RuleHis2}/>
                    </div>
                    <br/>
                    <br/>
                </div>
                <br/>
                <br/>
                <br/>
                <EndProject />
            </div>
        </div>
    );
}

export default ProjectGov3;