import ProjectBackButton from "../../components/projects/ProjectBackButton";
import React from "react";
import H2 from "../../components/projects/H2";
import EndProject from "../../components/projects/EndProject";
import ImageLeftParagraphRight from "../../components/projects/ImageLeftParagraphRight";
import Temp0 from "../../assets/images/pgov3/pgov3experdet/template0.png";
import Temp1 from "../../assets/images/pgov3/pgov3experdet/template1.png";
import Temp2 from "../../assets/images/pgov3/pgov3experdet/template2.png";
import Temp3 from "../../assets/images/pgov3/pgov3experdet/template3.png";
import Temp4 from "../../assets/images/pgov3/pgov3experdet/template4.png";
import Temp5 from "../../assets/images/pgov3/pgov3experdet/template5.png";
import Comp from "../../assets/images/pgov3/pgov3experdet/Comp_Icon.png";
import Success from "../../assets/images/pgov3/pgov3experdet/Success.png";
import TwoImages from "../../components/projects/TwoImages";
import LazyLoadImage from "../../components/LazyLoadImage";
import FlexTable from "../../components/projects/FlexTable";


function ProjectGov3ExperDetails() {
    return (
        <div className="Gov3ExperDetails pad-t-70px maxw640:pad-t-60px">
            <ProjectBackButton/>
            <div className="ExperimentDetails pad-t-70px">
                <H2 h2="Problem background"/>
                <div className="pad-l-8p pad-r-4p pad-b-2p">
                    <a className="color-blue-link text-underline" href={'#solution'}>Go to experiment details</a>
                </div>
                <div className="PainPoints flex">
                    <div className="CusPP flex-basis-50 pad-l-8p pad-r-4p pad-t-6p pad-b-6p bac-col-EDE8EA">
                        <h3 className="pad-b-10px">SaaS Customer Pain Point</h3>
                        <p>Following a specific SaaS customer action, their action may
                            be put on hold while the rule engine assesses for compliance. They
                            could be on hold for days or weeks.
                        </p>

                    </div>
                    <div className="BusPP flex-basis-50 pad-l-4p pad-r-8p pad-t-6p pad-b-6p bac-col-FAFAF2">
                        <h3 className="pad-b-10px">User Group Pain Points</h3>
                        <p>Editing or creating rules was inefficient and difficult. It was not possible to simulate
                            triggering a rule(s) to assess the best configuration and usage.
                        </p>
                    </div>
                </div>
                <div className="Temp_0 bac-col-white">
                    <ImageLeftParagraphRight image={Temp0} h3="New Technology"
                                             p="To help address both SaaS customer and user group pain points, the
                                             system was upgraded to more modern technologies and capabilities."/>
                </div>
                <div className="RuleT pad-l-8p pad-r-8p pad-t-4p pad-b-4p">
                    <h3 className="pad-b-10px">Rule Templates</h3>
                    <p>To design a UI that allowed users to create rules from rule
                        combinations, I needed to understand how the rules worked on a
                        technical level. To make the rules creatable, editable and combinable through the UI,
                        the developers used the abstract idea of rule templates. Each
                        rule template was represented by a Java Interface and each Interface
                        had defined characteristics. Each rule was a Java object
                        implementing a rule template. That meant that rules could have
                        the exact same characteristics but with different values. A rule could
                        also be a Java object with a combined implementation of 2 or more
                        interfaces, or 2 or more rule objects. From a user’s perspective,
                        that means when they select a template as a base for their new
                        combination rule, the characteristics are there but the values
                        were not filled in. If they opted for an already defined rule,
                        the values would be filled in matching the rule.</p>
                </div>
                <div className="Temp1Img mar-r-8p mar-l-8p pad-2p
                                border-5px-solid-E9ECEA
                                bac-col-white overflow-hid">
                    <LazyLoadImage src={Temp1}/>
                </div>
                <br/>
                <br/>
                <div className="Temp_1 bac-col-white">
                    <ImageLeftParagraphRight image={Temp2} h3="What happens to a triggered rule?"
                                             p="The system evaluates if the rule object characteristics and values
                                             match the characteristics and values of the SaaS customer's action.
                                             Depending on the outcome, the system returns the value true or false."/>
                </div>
                <div className="JavaLogEx pad-l-8p pad-r-8p pad-t-4p pad-b-4p">
                    <h3 className="pad-b-10px">Java Logical Expressions</h3>
                    <p>This works slightly differently if the rule object was created through a combination
                    of rule templates or objects. Each part of the rule, with it's own characteristics and
                        values, is evaluated separately. If the user joins these with the AND operator
                        both expressions must be true for the rule to fail. If they join these with the
                        OR operator, only 1 expression needs to be true for it to fail.
                    </p>
                </div>
                <TwoImages src1={Temp3} src2={Temp4}/>
                <br/>
                <br/>
                <div className="Temp_5 bac-col-white">
                    <ImageLeftParagraphRight image={Temp5} h3="3+ Combinations"
                                             p="Combining 2 expressions is the simplest way to combine.
                                                The more templates or rules combined into a rule the more
                                                complex it gets. There is a rule with 9 combinations,
                                                though it was expected to be the max number."/>
                </div>
                <div className="Rule4Com pad-l-8p pad-r-8p pad-t-4p pad-b-4p bac-col-E6EFEE">
                    <h3 className="pad-b-10px">Rules for Combining Rules</h3>
                    <ol className="pad-l-4p list-decimal">
                        <li>If any parts of the expression are in brackets, they take precedence and will be evaluated first.</li>
                        <li>The ‘AND’ operator take precedence over the ‘OR’ operator.</li>
                        <li>If there are two, or more, operators will similar precedence, the expression is evaluated from left to right.</li>
                        <li>Otherwise, the expression is evaluated from left to right.</li>
                        <li>If the ‘OR’ operator is used between expressions, if either of the expressions are true, then both return true. Otherwise, they return false.</li>
                        <li>If the ‘AND’ operator is used between expressions, if both of the expressions are true, then both return true. Otherwise, they return false.</li>
                        <li>If the rule expression evaluates true overall, then the rule is triggered. If it evaluates false overall, then the rule is not triggered.</li>
                    </ol>
                    <div id="solution">
                        <br/>
                    </div>
                </div>
                <br/>
                <div>
                    <H2 h2="Experiment Details"/>
                </div>
                <div className="RQuestHyp flex maxw640:flex-wrap">
                    <div className="RQuest pad-r-4p pad-t-6p pad-b-6p pad-l-8p pad-r-8p bac-col-E9ECEA">
                        <h3 className="pad-b-10px">Research Question</h3>
                        <p>How can we enable nontechnical users to learn to create complex
                            combination rules from the UI?
                        </p>
                    </div>
                    <div className="Hypot pad-l-4p pad-t-6p pad-b-6p pad-l-8p pad-r-8p bac-col-FAFAF2">
                        <h3 className="pad-b-10px">Hypothesis</h3>
                        <p>Providing guidelines and examples will help users to learn
                            how to create complex combination rules and increase success rate.
                        </p>
                    </div>
                </div>
                <br/>
                <div className="ProGov3ExperDetails flex maxw640:flex-wrap align-i-center justify-i-center
                                text-al-center bac-col-E6EFEE">
                    <div className="flex-basis-30 maxw640:flex-100p maxw640:pad-tb-8p-10p height-40p pad-3p bac-col-EDE8EA">
                        <img className="mar-auto" src={Comp} alt="Icon of hands using computer" width="40%"/>
                        <div className="color-1A3F4"><h2>Control Group</h2></div>
                        <div>Create combined rules without guidelines and examples</div>
                    </div>
                    <div className="flex-basis-30  maxw640:flex-100p maxw640:pad-tb-8p-10p
                                    height-40p pad-3p bac-col-FAFAF2">
                        <img className="mar-auto" src={Comp} alt="Icon of hands using computer" width="40%"/>
                        <div><h2>Experimental Group</h2></div>
                        <div>Create combined rules using guidelines and examples</div>
                    </div>
                    <div className="flex-basis-40 maxw640:flex-100p maxw640:pad-tb-8p-10p bac-col-E6EFEE">
                        <div className="SuccessRate
                                        width-100p
                                        ">
                            <img className="mar-auto" src={Success} alt="Icon of success badge" width="25%"/>
                            <div><h2 className="color-1A3F45">Metric:</h2></div>
                            <div className="MetName text-al-center font-size-1p5em">Success Rate</div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="ExperDets flex maxw640:flex-wrap
                                bac-col-EDE8EA">
                    <div className="Control flex-basis-50 maxw640:flex-100p
                                    pad-t-2p pad-l-8p pad-r-4p">
                        <div className="CGResultsR1
                                        pad-r-4p pad-t-8p pad-b-4p">
                            <h3 className="pad-b-10px">Control Group Process & Results</h3>
                            <br/>
                            <h4 className="pad-b-10px">Round 1</h4>
                            <div>
                                <div className="flex">
                                    <div className="flex-shrink0 pad-r-20px"><p>10 mins</p></div>
                                    <div><p>Decided if expressions returned true or false for rules
                                        combining 2 to 5 expressions</p></div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink0 pad-r-20px"><p>15 mins</p></div>
                                    <div><p>Brief interview</p></div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="CGResultsR1Results pad-b-2p bac-col-EDE8EA">
                            <div className="pad-b-6p">
                                <h4>Round 1 Results (Success Rate)</h4>
                                <p>2 exp – 20%, 3 exp – 0%, 4 exp – 0%, 5 exp – 0%</p>
                            </div>
                        </div>
                        <div className="CGResultsR1Quote pad-b-2p bac-col-EDE8EA">
                            <div className="text-al-center">
                                <h3>"I mostly guessed the answers. I didn't understand how it worked"</h3>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                    <div className="Experiment flex-basis-50 maxw640:flex-100p
                                    pad-l-4p pad-r-8p pad-t-2p bac-col-FAFAF2">
                        <div className="EGResults1
                                        pad-t-8p pad-b-4p bac-col-FAFAF2">
                            <h3 className="pad-b-10px">Experimental Group Process & Results</h3>
                            <br/>
                            <h4 className="pad-b-10px">Round 1</h4>
                            <div>
                                <div className="flex">
                                    <div className="flex-shrink0 pad-r-20px"><p>15 mins</p></div>
                                    <div><p>Studied guidelines and examples</p></div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink0 pad-r-20px"><p>10 mins</p></div>
                                    <div><p>Decided if expressions returned true or false for rules
                                        combining 2 to 5 expressions</p></div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink0 pad-r-20px"><p>15 mins</p></div>
                                    <div><p>Brief interview</p></div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="EGResultsR1Results pad-b-2p">
                            <div className="pad-b-6p">
                                <h4>Round 1 Results (Success Rate)</h4>
                                <p>2 exp – 100%, 3 exp – 60%, 4 exp – 20%, 5 exp – 0%</p>
                            </div>
                        </div>
                        <div className="EGResultsR1Quote pad-b-2p">
                            <div className="text-al-center pad-b-6p">
                                <h3>"The guide helped, though I still wasn't sure for the harder ones"</h3>
                            </div>
                        </div>
                        <br/>
                        <div className="EGResultsR2 pad-b-4p">
                            <div className="pad-b-6p">
                                <p>Based on feedback from the participants of round 1, changes to the wording
                                    of the guidelines and examples were made.</p>
                            </div>
                            <br/>
                            <h4 className="pad-b-10px">Round 2</h4>
                            <div>
                                <div className="flex">
                                    <div className="flex-shrink0 pad-r-20px"><p>15 mins</p></div>
                                    <div><p>Studied updated guidelines and examples</p></div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink0 pad-r-20px"><p>10 mins</p></div>
                                    <div><p>Decided if expressions returned true or false for rules
                                        combining 2 to 5 expressions</p></div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink0 pad-r-20px"><p>15 mins</p></div>
                                    <div><p>Brief interview</p></div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="EGResultsR1Results pad-b-2p">
                            <div className="pad-b-6p">
                                <h4>Round 2 Results (Success Rate)</h4>
                                <p>2 exp – 100%, 3 exp – 80%, 4 exp – 20%, 5 exp – 0%</p>
                            </div>
                        </div>
                        <div className="EGResultsR2Quote pad-b-2p">
                            <div className="text-al-center pad-b-6p">
                                <h3>"2 & 3 combinations were fine. I had to think a lot for the others, it
                                    was confusing."</h3>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="pad-l-8p pad-r-8p pad-t-4p pad-b-2p">
                <h3 className="pad-b-10px">Comparing the results</h3>
            </div>
            <FlexTable/>
            <div className="pad-l-8p pad-r-8p pad-t-4p pad-b-4p">
                <p className="pad-b-10px">Based on the results it was concluded that:</p>
                <ul>
                    <li>Guidelines and examples should be available
                        on the UI for combining 2 and 3 expressions.</li>
                    <li>Rules with 4 or more combinations should be created/edited by
                        a developer, or reviewed by a developer.</li>
                    <li>Developers should be able to combine expressions to create rules through
                        a text box on the UI.</li>
                </ul>
                <br/>
                <br/>
            </div>
            <div className="pad-l-8p pad-r-4p pad-b-2p">
                <a className="color-blue-link text-underline" href={'/Portfolio/PublicBody1System#problem'}>
                    Go to back to problem</a>
            </div>
            <br/>
            <br/>
            <br/>
            <EndProject/>
        </div>
    );
}

export default ProjectGov3ExperDetails;