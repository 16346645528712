import React from "react";

class H2andParagraph extends React.Component {
    render() {
        return (
            <div className="H2andParagraph
                            flex maxw640:flex-wrap
                            pad-l-8p pad-r-8p pad-t-4p pad-b-4p
                            maxw640:pad-tb-10p
                            bac-col-white">
                <div className="H2Pheading
                                flex-basis-40 maxw640:flex-100p
                                maxw640:text-a-l maxw640:pad-b-4p
                                mar-auto text-al-center">
                    <h2>{this.props.header}</h2>
                </div>
                <div className="H2Ptext
                                flex-basis-60  maxw640:flex-100p">
                    <p>{this.props.text}</p>
                </div>
            </div>
        );
    }
}

export default H2andParagraph;