import React from 'react';
import {Nav, Navbar, NavItem} from 'react-bootstrap';
import {Link} from "react-router-dom";

class HeaderNav extends React.Component {

    render() {
        return (
            <Navbar className="flex
                               maxw768:mar-auto-20px
                               a-color-2C a-text-dec-none">
                <Nav className="flex align-i-center
                                pad-l-20px pad-r-20px maxw768:pad-lr-10px border-rad-50px
                                bac-col-f1f9f9-t6 backdrop-blur-md">
                    <NavItem className="maxw320:font-size-1em font-size-1p3em cursor-pointer hover-underline-pink">
                        <Link to={`/Portfolio`}>Portfolio</Link>
                    </NavItem>
                    <NavItem className="maxw320:font-size-1em font-size-1p3em mar-l-50px maxw375:mar-l-20px hover-underline-pink">
                        <Link to={`/AboutContact`}>About/Contact</Link>
                    </NavItem>
                </Nav>
            </Navbar>
        );
    }
}

export default HeaderNav;