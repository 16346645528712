import HeaderLogo from "../components/header/HeaderLogo";
import React from "react";
import HeaderNav from "../components/header/HeaderNav";

function Header() {
    return (
        <div className="z-100 pos-fixed width-100vw
                        flex justify-between
                        minw640:p-y-1 minw769:pad-lr-6p7p
                        maxw640:pad-tb-0p5rem">
            <HeaderLogo/>
            <HeaderNav/>
        </div>
    );
}

export default Header;