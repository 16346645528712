
function HiCircle() {

    return (
        <div className="HiCircle z-14 pos-absolute
                        flex justify-center minw769:align-i-center
                        width-18vw height-18vw
                        max-width-230px max-height-230px
                        min-width-200px min-height-200px
                        bac-col-2C border-rad-100p border-sol-4px-FAFA8B
                        maxw2560:font-size-7em maxw768:font-size-5em
                        text-al-center font-weight-400
                        animation-name-rotate animation-delay-p5s animation-dur-2s">
            <span className="ver-align-mid mar-auto color-white">Hi!</span>
        </div>
    );
}

export default HiCircle;