import SAPHero from '../../assets/images/psap/SAPHero.png';
import TopHeroImage from "../../components/projects/TopHeroImage";
import ProjectBackButton from "../../components/projects/ProjectBackButton";
import HeadingProject from "../../components/projects/HeadingProject";
import OutlineRole from "../../components/projects/OutlineRole";
import NDAInfo from "../../components/projects/NDAInfo";
import EndProject from "../../components/projects/EndProject";
import React from "react";

function ProjectSAP() {
    return (
        <div className="ProjectSAP pad-t-70px maxw640:pad-t-60px">
            <ProjectBackButton/>
            <TopHeroImage src={SAPHero}/>
            <div className="SAPHead">
                <HeadingProject heading="SAP Cross Database Comparison Tool"/>
                <OutlineRole outline="The Cross-Database Comparison tool is an SAP Solution Manager
                                    application in the area of data consistency management. It is used to
                                    compare data sources with a complex structure or hierarchy across different
                                    systems. The user can set up and run a comparison and are displayed a result,
                                    allowing them to check whether the data in the source and target systems is
                                    consistent. This was a data visualization project, where all comparison
                                    results were to be displayed through a dashboard with aggregate metrics, as well as,
                                    a detailed view of each comparison result set. These were to be self-contained and
                                    displayed in their own cloud-based application."
                                  role="I was the project lead, UX designer and UI developer for this project, responsible
                                    for all reporting, planning, design and implementation. I had 2 other developers
                                    working with me."/>
            </div>
            <br/>
            <br/>
            <br/>
            <EndProject />
        </div>
    );
}

export default ProjectSAP;