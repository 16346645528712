import React from "react";

class H3 extends React.Component {
    render() {
        return (
            <div className="H3 width-100p text-al-left pad-t-1p maxw640:pad-t-4p pad-l-8p pad-r-8p pad-b-2p">
                <h3>{this.props.h3}</h3>
            </div>
        );
    }
}

export default H3;