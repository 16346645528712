import { withRouter } from 'react-router-dom';
import React from "react";

class ScrollToTop extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname === "/Portfolio") {
            document.getElementById('portfolio').scrollIntoView();
        } else if (this.props.location.pathname === "/") {
            window.scrollTo(0, 0);
        } else {
            if (this.props.location.pathname !== prevProps.location.pathname) {
                window.scrollTo(0, 0);
            }
        }
    }
    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);