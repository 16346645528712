import ImageLeftTitle from "./ImageLeftTitle";
import ImageRightTitle from "./ImageRightTitle";
import GovLogo from '../../assets/images/pgov/Gov_Logo.png';
import GovLogo2 from '../../assets/images/pgov/GovLogo_green.PNG';
import Wholesalers from '../../assets/images/pwholesalers/Food_Wholesalers.png';
import SAPLogo from '../../assets/images/psap/sap_logo.png';
import CurioLogo from '../../assets/images/pcurio/Curio_logo.png';
import CompfyK from '../../assets/images/pthesis/CompfyK.png';
import React from "react";
import { instanceOf } from "prop-types";
import {withCookies, Cookies} from "react-cookie";
import {Link} from "react-router-dom";
import InputDialog from "../InputDialog";
import Footer from "../../parts/Footer";

class Portfolio extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    state = {
        user: this.props.cookies.get("user") || ""
    };

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrollingDown);
        window.scrollBy(0,-100);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const wrappedElement = document.getElementById('portfolioHeader');
        if (window.scrollY > 500) {
            window.scrollBy(0,-100);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrollingDown);
        document.removeEventListener('scroll', this.trackScrollingUp);
    }

    trackScrollingDown = () => {
        const wrappedElement = document.getElementById('portfolioHeader');
        const pwdDialog = document.getElementById("PwdDialog");
        if (window.scrollY > (wrappedElement.offsetTop + wrappedElement.offsetHeight)) {
            window.history.pushState({}, null, "/Portfolio");
            const { cookies } = this.props;
            this.setState({ user: cookies.get("user") });
            if (this.state.user === undefined || this.state.user === "") { // if user cookie not set
                pwdDialog.style.display = "block"; // show password dialog
            }
            document.removeEventListener('scroll', this.trackScrollingDown);
            document.addEventListener('scroll', this.trackScrollingUp);
        }

    };

    trackScrollingUp = () => {
            const portfolioHeader = document.getElementById('portfolioHeader');
            const pwdDialog = document.getElementById("PwdDialog");
            if (window.scrollY < (portfolioHeader.offsetTop + portfolioHeader.offsetHeight)) {
                window.history.pushState({}, null, "/");
                pwdDialog.style.display = "none";
                document.removeEventListener('scroll', this.trackScrollingUp);
                document.addEventListener('scroll', this.trackScrollingDown);
            }
    };

    checkLinkActive = (event) => {
        const { cookies } = this.props;
        this.setState({ user: cookies.get("user") });
        const allUrl = event.currentTarget.href;
        const url = allUrl.substring(allUrl.indexOf('/Portfolio/'));
        const portfolioHeader = document.getElementById('portfolioHeader');
        const pwdDialog = document.getElementById("PwdDialog");
        if (window.scrollY > (portfolioHeader.offsetTop + portfolioHeader.offsetHeight) &&
            (pwdDialog.style.display === "none" || pwdDialog.style.display === "") &&
            (this.state.user !== undefined || this.state.user !== "")) {
            window.history.pushState({}, null, url);
        } else {
            pwdDialog.style.display = "block";
            pwdDialog.classList.add('animation-dur-1s');
            pwdDialog.classList.add('animation-name-flash');
            const pwdError2 = document.getElementById("PwdError2");
            pwdError2.style.display = "block";
            event.preventDefault();
        }
    }


    render() {
        return (
            <div className="HomeBelow">
                <div id={"portfolio"}
                     className="Portfolio z-20 pos-absolute width-100p
                                maxw280:top-180vw
                                maxw440:top-135vw
                                maxw565:top-115vw
                                maxw768:top-88vw
                                minw769:top-65vw
                                minw1024:top-50vw
                                minw1180:top-48vw
                                minw1280:top-40vw
                                minw1920:top-35vw
                                a-color-2C a-text-dec-none a-hover-color-white
                                pad-l-10p pad-r-10p maxw800:pad-lr-30px maxw768:pad-lr-10px maxw440:pad-lr-5px">
                    <div id={"portfolioHeader"}>
                        <h1 className="pad-l-20px pad-r-20px pad-t-10px pad-b-10px bac-col-2C color-white width-fit
                                       font-weight-400">
                            Portfolio
                        </h1>
                    </div>
                    {/* eslint-disable-next-line no-undef */}
                    <Link to={`/Portfolio/PublicBody1System`}  onClick={this.checkLinkActive}>
                        <ImageLeftTitle src={GovLogo}
                                        year="2022/21"
                                        title="Specialised system for public body 1"
                                        subtitle="UX Research | UX Design | UI Design | User Testing"/>
                    </Link>
                    <Link to={`/Portfolio/PublicBody1Benchmarking`}  onClick={this.checkLinkActive}>
                        <ImageRightTitle src={GovLogo}
                                         year="2022"
                                         title="Efficiency benchmarking for public body 1"
                                         subtitle="UX Research | UX Design | UI Design | User Testing"/>
                    </Link>
                    <Link to={`/Portfolio/PublicBody2System`}  onClick={this.checkLinkActive}>
                        <ImageLeftTitle src={GovLogo2}
                                        year="2020"
                                        title="Specialised system for public body 2"
                                        subtitle="UX Research | UX Design | UI Design | User Testing"/>
                    </Link>
                    {/*<Link to={`/Portfolio/WholesalerSystem`}  onClick={this.checkLinkActive}>*/}
                    <div className="bac-col-2C-trans">
                        <div className="pos-absolute text-white font-size-2p5em bac-col-2C pad-2p">
                            COMING SOON
                        </div>
                        <ImageRightTitle src={Wholesalers}
                                         year="2019"
                                         title="Invoicing system for a food wholesaler"
                                         subtitle="UX Research | UX Design | UI Design | User Testing"/>
                    </div>
                     {/*</Link>*/}
                    <Link to={`/Portfolio/SAPCDCtool`}  onClick={this.checkLinkActive}>
                        <ImageLeftTitle src={SAPLogo}
                                        year="2018"
                                        title="SAP Cross-Database Comparison tool"
                                        subtitle="UX Design | User Testing | Development"/>
                    </Link>
                     {/*<Link to={`/Portfolio/CurioResearch`}  onClick={this.checkLinkActive}>
                        <ImageRightTitle src={CurioLogo}
                                         title="Curio.xyz tree management system"
                                         subtitle="UX Research"/>
                    </Link>
                    <Link to={`/Portfolio/MScUXThesis`}  onClick={this.checkLinkActive}>
                        <ImageLeftTitle src={CompfyK}
                                        title="Messaging app for prevention of MSDs"
                                        subtitle="M.Sc. UX Design Thesis Project"/>
                    </Link>
                    */}
                    <div className="FooterWrap bottom-0">
                        <Footer/>
                    </div>
                </div>
                <div id="PwdDialog" className="PwdDialog z-99 pos-fixed width-100p bottom-20p none">
                    <InputDialog heading="Password Protected"
                                 description="Enter password to access portfolio:"/>
                </div>
            </div>
        );
    }
}

export default withCookies(Portfolio);