
function ProjectBackButton() {
    return (
        <div className="ProjectBackButton pos-fixed z-99
                        mar-l-8p mar-t-10px
                        pad-l-20px pad-r-20px pad-t-10px pad-b-10px border-rad-50px
                        bac-col-f1f9f9-t6 backdrop-blur">
            <button  className="font-size-1p2em font-weight-500
                                color-blue-link
                                border-underline-transparent hover-underline-blue
                                cursor-pointer"
                     onClick={() => {window.history.go(-2);}}>
                <i className="fa fa-arrow-left pad-r-10px color-blue-link"></i>
                Back
            </button>
        </div>
    );
}

export default ProjectBackButton;