import React from "react";

class ProjectStats1 extends React.Component {
    render() {
        return (
            <div className="ProjectStats1
                            width-100p maxw640:flex-100p
                            pad-t-4p pad-b-4p
                            maxw640:pad-tb-8p-10p
                            bac-col-E9ECEA">
                <div className="StatQty text-al-center font-weight-700 font-size-3em color-1A3F45">
                    {this.props.number}
                </div>
                <div className="StatName text-al-center font-size-1p5em">
                    {this.props.name}
                </div>
            </div>
        );
    }
}

export default ProjectStats1;