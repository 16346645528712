import React from 'react';

class ProblemSolution extends React.Component{

    render()
    {
        return (
            <div className="ProblemSolution">
                <div className="Problem
                                flex maxw640:flex-wrap
                                border-1px-solid-EDE8EA
                                bac-col-EDE8EA
                                align-i-center">
                    <div className="ProblemHeading
                                    flex-basis-40 maxw640:flex-100p
                                    maxw640:pad-4p
                                    text-al-center">
                        <h2>Problem</h2>
                    </div>
                    <div className="ProblemText
                                    flex-basis-60 maxw640:flex-100p
                                    pad-t-2p pad-b-2p pad-l-4p pad-r-8p
                                    maxw640:pad-l-8p maxw640:pad-tb-6p
                                    bac-col-white">
                        <p>{this.props.problem}</p>
                    </div>
                </div>
                <div className="Solution
                                flex maxw640:flex-wrap
                                border-1px-solid-E6EFEE
                                bac-col-E6EFEE
                                align-i-center">
                    <div className="SolutionText
                                    flex-basis-60 maxw640:flex-100p
                                    pad-t-2p pad-b-2p pad-l-8p pad-r-8p
                                    maxw640:pad-l-8p maxw640:pad-tb-6p
                                    bac-col-white">
                        <p>{this.props.solution}</p>
                    </div>
                    <div className="SolutionHeading
                                    flex-basis-40 maxw640:flex-100p
                                    maxw640:pad-4p
                                    text-al-center">
                        <h2>Solution</h2>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProblemSolution;