import React from "react";

class ProjectInfo4 extends React.Component {
    render() {
        return (
            <div className="ProjectInfo4
                            width-100p
                            maxw640:flex-100p
                            pad-t-5p pad-b-5p maxw640:pad-tb-8p-10p
                            bac-col-E9ECEA">
                <div className="InfoHead
                                text-al-center font-weight-700 font-size-2p5em maxw768:font-size-2em
                                color-1A3F45">
                    {this.props.head}
                </div>
                <div className="InfoSubhead text-al-center font-size-1p5em">
                    {this.props.sub}
                </div>
            </div>
        );
    }
}

export default ProjectInfo4;