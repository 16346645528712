import React from 'react';

class ProjectTitle extends React.Component {

    render() {
        return (
            <div className="maxw440:pad-tb-20px maxw768:col-white">
                <div className="minw1120:font-size-1p5em minw972:font-size-1p3em font-size-1em
                                font-fam-con-light font-weight-500">
                    {this.props.year}
                </div>
                <div className="minw1120:font-size-2p5em minw972:font-size-2em font-size-1p5em
                                font-weight-500 pad-tb-30px">
                    {this.props.title}
                </div>
                <div className="minw1120:font-size-1p5em minw972:font-size-1p3em font-size-1em
                                font-fam-con-light font-weight-500">
                    {this.props.subtitle}
                </div>
            </div>
        );
    }
}

export default ProjectTitle;