import React from 'react';

class LazyLoadImage extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            uniqueId: null
        };
    }

    render()
    {
        const preload = `${this.props.src}?q=20`;
        const container = `container-${this.state.uniqueId}`;
        const placeholder = `placeholder-${this.state.uniqueId}`;
        const lazyloadimage = `lazyloadimage-${this.state.uniqueId}`;

        return (
            <div id={container} className="pro-img-con bac-col-f6 bac-rep-no width-100p pad-0-notImp pos-relative
                                            overflow-vis trans-bac-1s-lin">
                <img
                    id={placeholder}
                    src={preload}
                    className="max-width-100p filter-blur-50px pos-absolute transform-scale-1 trans-dis-1s-lin
                                width-100p block"
                    alt={"Placeholder"}
                />
                <img
                    id={lazyloadimage}
                    src={this.props.src}
                    className="pro-img max-width-100p width-100p opacity-0 block trans-opac-1s-lin z-1"
                    alt={this.props.alt}
                />
            </div>
        );
    }

    componentDidMount() {
        this.generateUnqiueId();
    }

    generateUnqiueId() {
        let uniqueId = "";
        const selection =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        for (var i = 0; i < 5; i++) {
            uniqueId += selection.charAt(
                Math.floor(Math.random() * selection.length)
            );
        }
        this.setState({
            uniqueId: uniqueId
        }, () =>this.updateElements());
    }

    updateElements() {
        const container = document.querySelector(
            `#container-${this.state.uniqueId}`
        );
        const placeholder = document.querySelector(
            `#placeholder-${this.state.uniqueId}`
        );
        const lazyloadimage =
            document.querySelector(`#lazyloadimage-${this.state.uniqueId}`);
        lazyloadimage.onload = () => {
            lazyloadimage.setAttribute("style", "opacity: 1");
            container.setAttribute("style", "background: none");
            placeholder.setAttribute("style", "display: none");
        };
    }
}

export default LazyLoadImage;