import RachaelLogo from '../../assets/images/logo.svg';
import {Link} from "react-router-dom";
import React from "react";

function HeaderLogo() {

    return (
        <div className="maxw768:pad-lr-10px a-color-2C a-text-dec-none cursor-pointer">
            <Link to={`/`}>
                <div className="flex align-i-center
                                pad-l-20px pad-r-20px maxw768:pad-lr-5px
                                pad-t-10px pad-b-10px maxw768:pad-tb-5px
                                border-rad-50px
                                bac-col-f1f9f9-t6 backdrop-blur">
                    <img src={RachaelLogo} alt="Logo" className="min-width-40px max-width-40px"/>
                    <h2 className="maxw580:display-none mar-l-20px
                                   ">RACHAEL ELDER</h2>
                </div>
            </Link>
        </div>
    );
}

export default HeaderLogo;