import React from "react";
import { Switch, Route } from "react-router-dom";
import AboutContact from "./AboutContact";
import ProjectGov from "./projects/ProjectGov";
import ProjectGov2 from "./projects/ProjectGov2";
import ProjectGov3 from "./projects/ProjectGov3";
import ProjectWholesaler from "./projects/ProjectWholesaler";
import ProjectSAP from "./projects/ProjectSAP";
import ProjectCurio from "./projects/ProjectCurio";
import ProjectThesis from "./projects/ProjectThesis";
import Home from "./Home";
import ScrollToTop from "../components/ScrollToTop";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ProjectGov3ExperDetails from "./projects/ProjectGov3ExperDetails";


function Main() {

    return (
        <div className="Main pos-relative min-height-100vh">
            <ScrollToTop/>
            <ToastContainer position="bottom-center"/>
            <Switch>
                <Route path="/Portfolio/PublicBody1System">
                    <ProjectGov3/>
                </Route>
                <Route path="/PublicBody1System/ExperimentDetails">
                    <ProjectGov3ExperDetails/>
                </Route>
                <Route path="/Portfolio/PublicBody1Benchmarking">
                    <ProjectGov2/>
                </Route>
                <Route path="/Portfolio/PublicBody2System">
                    <ProjectGov/>
                </Route>
                <Route path="/Portfolio/WholesalerSystem">
                    <ProjectWholesaler/>
                </Route>
                <Route path="/Portfolio/SAPCDCtool">
                    <ProjectSAP/>
                </Route>
                {/*
                <Route path="/Portfolio/CurioResearch">
                    <ProjectCurio/>
                </Route>
                <Route path="/Portfolio/MScUXThesis">
                    <ProjectThesis/>
                </Route>
                */}
                <Route path="/AboutContact">
                    <AboutContact/>
                </Route>
                <Route exact path="/Portfolio">
                    <Home/>
                </Route>
                <Route exact path="/">
                    <Home/>
                </Route>
            </Switch>
        </div>
    );
}

export default Main;
