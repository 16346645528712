import React from 'react';

class NDAInfo extends React.Component{

    render()
    {
        return (
            <div className="NDAInfo
                            mar-l-8p mar-r-8p
                            pad-b-1p
                            font-fam-con-light font-size-1em font-weight-500">
                ** As I have signed a non-disclosure agreement in relation to this piece of work,
                information has been generalised, blurred, or modified whilst still displaying my design process
                and problem solving capabilities.
            </div>
        );
    }
}

export default NDAInfo;