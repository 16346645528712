import React from "react";

class ScrollTopButton extends React.Component {

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrollDown);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrollDown);
        document.removeEventListener('scroll', this.trackScrollUp);
    }

    trackScrollDown = () => {
        const x = document.getElementById("ScrollTopButton");
        if (window.scrollY > 50) {
            x.style.display = "block";
            document.removeEventListener('scroll', this.trackScrollDown);
            document.addEventListener('scroll', this.trackScrollUp);
        }

    };

    trackScrollUp = () => {
        const x = document.getElementById("ScrollTopButton");
        if (window.scrollY < 50) {
            x.style.display = "none";
            document.removeEventListener('scroll', this.trackScrollUp);
            document.addEventListener('scroll', this.trackScrollDown);
        }
    };


    render() {

        const backToTop = () => {
            window.scrollTo(0,0);
        }

        return (
            <div id="ScrollTopButton" className="none">
                <button className="ScrollTopButton flex align-i-center justify-center
                                    width-3rem height-3rem color-white
                                    border-rad-100p bg-opacity-25 bac-col-rgba293033 appear-none scale-90
                                    tran-prop-B2T tran-dur-p1s tran-t-func-B2T transform-B2T
                                    hover-scale-100 hover-bg-opacity-100 focus-outline-none"
                        onClick={backToTop}>
                    <svg className="width-1p5rem height-1p5rem block ver-align-mid" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M.258 10.008l.816.86c.215.214.559.214.73 0l6.704-6.661v14.652c0
                                    .301.215.516.515.516h1.204a.522.522 0 00.515-.516V4.207l6.66 6.66c.172.215.516.215.73
                                    0l.817-.86c.215-.171.215-.515 0-.73L9.97.297a.495.495 0 00-.73 0L.257
                                    9.277c-.215.215-.215.559 0 .73z"
                              fill="white"/>
                    </svg>
                </button>
            </div>
        );
    }
}

export default ScrollTopButton;