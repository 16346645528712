import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from "react-cookie";
import './assets/css/index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import Header from "./parts/Header";
import Main from "./pages/Main";
import Footer from "./parts/Footer";
import ScrollTopButton from "./parts/ScrollTopButton";

ReactDOM.render(
  <React.StrictMode>
      <CookiesProvider>
      <BrowserRouter>
          <Header/>
          <div className="SiteContainer pos-relative min-height-100vh pad-b-2rem">
              <Main/>
              <div className="BackToTop pos-fixed bottom-0 right-0 z-99 pad-1p5rem block">
                  <ScrollTopButton/>
              </div>
          </div>
          {/*<div className="FooterWrap bottom-0">
              <Footer/>
          </div>*/}
      </BrowserRouter>
      </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
