import React, { useState } from "react";
import { useCookies} from 'react-cookie';
import checkfile from '../assets/json/checkfile.json';
import {toast} from "react-toastify";

export function InputDialog(props) {
    const [password, handleChange] = useState("");
    const [removeCookie, setCookie] = useCookies(["user"]);

    function handleSubmit(event){
        let checkflag = false;
        for (let i = 0; i < checkfile.length; i++) {
            let obj = checkfile[i];
            if (obj.password === password){
                checkflag = true;
            }
        }
        const pwdDialog = document.getElementById("PwdDialog");
        if (checkflag) { // if password is a match
            let expires = new Date(); // cookie expiry date
            expires.setDate(expires.getDate() + 70); // add 10 weeks
            let date = expires.getDate();
            let month = expires.getMonth()+1;
            let year = expires.getFullYear();
            setCookie('user', password, {expires: expires});
            pwdDialog.style.display = "none"; // hide password dialog
            toast.dark("You have password free access until " + date + "." + month + "." + year + " (10 weeks from now).");
        } else { // password not a match
            const pwdError = document.getElementById("PwdError");
            pwdError.style.display = "block"; // show password dialog
        }
        event.preventDefault();
    }

    function handleFocus(event) {
        const pwdError = document.getElementById("PwdError");
        pwdError.style.display = "none";
        const pwdError2 = document.getElementById("PwdError2");
        pwdError2.style.display = "none";
        document.getElementById("pwd").value='';
    }

    return (
        <div className="InputDialog z-99 block
                        max-width-600px
                        mar-l-auto mar-r-auto
                        pad-l-80px pad-r-80px maxw565:pad-rl-30px
                        pad-t-40px pad-b-40px
                        bac-col-white border-rad-5px
                        box-shadow-card hover-box-sha-08160">
            <div className="flex align-i-center justify-i-center">
                <div className="maxw565:display-none">
                    <i className="fas fa-lock font-size-3em color-2C mar-r-40px" aria-hidden="true"/>
                </div>
                <div>
                    <h2 className="color-1A3F45">{props.heading}</h2>
                    <form onSubmit={handleSubmit}>
                        <h3 className="pad-t-10px pad-b-20px">
                            {props.description}
                        </h3>
                        <input type="password" id="pwd" name="pwd"
                               value={password}
                               onChange={e => handleChange(e.target.value)}
                               onFocus={handleFocus}
                               className="width-200px mar-r-20px pad-5px
                                          border-1px-solid-ccc border-rad-4px
                                          font-size-1p2em"/>
                        <input type="submit" value="Enter"
                               className="width-100px text-al-center
                                          bac-col-1A3F45 color-white
                                          maxw440:mar-t-10px
                                          pad-5px border-rad-4px
                                          font-fam-con font-size-1p2em
                                          cursor-pointer"/>
                        <div id="PwdError" className="width-90p color-red pad-t-10px none">
                            The password you entered is incorrect or no longer active. Please contact
                            me directly for access.
                        </div>
                        <div id="PwdError2" className="width-90p color-red pad-t-10px none">
                            A password must be entered to access portfolio.
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default InputDialog;