import React from "react";
import LazyLoadImage from "../LazyLoadImage";

class ImageLeftParagraphRight extends React.Component {
    render() {
        return (
            <div className="ILPR flex maxw640:flex-wrap align-i-center maxw640:flex-wrap
                                 width-100p pad-l-8p pad-r-8p pad-t-4p pad-b-4p">
                <div className="flex-basis-60 maxw640:flex-100p">
                    <LazyLoadImage src={this.props.image}/>
                </div>
                <div className="flex-basis-40 maxw640:flex-100p pad-l-8p">
                    <h3 className="pad-b-10px">{this.props.h3}</h3>
                    <p>{this.props.p}</p>
                </div>
            </div>
        );
    }
}

export default ImageLeftParagraphRight;