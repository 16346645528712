import LinkedInIcon from '../../assets/images/aboutcontact/LinkedIn_Icon.svg';
import EmailIcon from '../../assets/images/aboutcontact/Email_Icon.svg';

function Contact() {
    return (
        <div className="Contact
                        flex-basis-25 maxw768:flex-100p
                        border-l-sol-5px-A8B3AB
                        pad-t-4p pad-b-4p pad-l-3p pad-r-3p
                        bac-col-white">
            <div className="pad-b-10px color-1A3F45">
                <h2>Contact Me</h2>
            </div>
            <div className="ContactMessage
                            pad-t-8p maxw768:pad-t-10px pad-b-15p maxw768:pad-b-20px
                            font-size-1p2em font-fam-con-light font-weight-500">
                I'm always up for meeting new
                people and chatting all
                things UX, creative thinking,
                innovation and
                design. Feel free to add me
                on LinkedIn and/or reach out
                by email.
            </div>
            <div className="LinkedIn flex justify-i-center pad-t-4p pad-b-6p maxw768:pad-tb-10px">
                <div className="LinkedInIcon width-30px">
                    <img src={LinkedInIcon} className="block mar-l-auto mar-r-auto" alt={"LinkedIn Icon"}/>
                </div>
                <div className="LinkedInTitle flex-basis-88 pad-l-6p">
                    <a href="https://www.linkedin.com/in/rachaelelder/">
                        LinkedIn
                    </a>
                </div>
            </div>
            <div className="Email flex justify-i-center pad-t-4p pad-b-4p maxw768:pad-tb-10px">
                <div className="EmailIcon width-30px max-width-30px max-height-30px">
                    <img src={EmailIcon} alt={"Email Icon"}/>
                </div>
                <div className="EmailAddress flex-basis-88 pad-l-6p ">
                    <a href="mailto:rchlelder@gmail.com?subject=👋 Hello">
                        rchlelder@gmail.com
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Contact;