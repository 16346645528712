import HiCircle from "./HiCircle";

function HiCircleBackground() {
    return (
        <div className="HiCircleBackground z-8
                        pos-absolute
                        minw769:mar-l-m33p5vw maxw768:center-circles
                        minw769:top-m15vw
                        minw912:top-m16vw
                        minw962:top-m18p7vw minw962:mar-l-m3 2vw
                        minw1024:top-m25vw
                        minw1280:top-m30p5vw minw1280:margin-left-m35p5vw
                        minw1400:top-m31p75vw minw1400:mar-l-m36vw
                        minw1600:top-m34vw minw1600:mar-l-m38p5vw
                        minw1920:top-m37p5vw minw1920:mar-l-m41vw
                        minw2256:top-m40p25vw minw2256:mar-l-m43vw
                        minw2560:top-m42vw minw2560:mar-l-m45vw
                        maxw768:top-m10vw maxw640:top-m12vw maxw565:top-m8vw maxw480:top-5vw maxw440:top-18vw maxw280:top-12vw
                        flex align-i-center justify-center
                        width-110vw height-110vw
                        min-width-345px min-height-345px">
            <div className="flex align-i-center justify-center
                            bac-col-DAE7E6 border-rad-100p
                            width-110vw height-110vw
                            animation-name-growcircle animation-delay-p5s animation-dur-2s">
                <div className="flex align-i-center justify-center
                                bac-col-CDDFDD border-rad-100p
                                width-82vw height-82vw
                                max-width-630px max-height-630px
                                min-width-345px min-height-345px">
                    <div className="flex align-i-center justify-center
                                    bac-col-D2C6CB border-rad-100p
                                    width-58vw height-58vw
                                    max-width-450px max-height-450px
                                    min-width-280px min-height-280px
                                    top-7vw left-7vw">
                        <div className="flex align-i-center justify-center
                                        bac-col-A8B3AB border-rad-100p
                                        width-42vw height-42vw
                                        max-width-330px max-height-330px
                                        min-width-230px min-height-230px
                                        top-4p5vw left-4p5vw">

                        </div>
                    </div>
                </div>
            </div>
            <HiCircle/>
        </div>
    );
}

export default HiCircleBackground;