import React from 'react';

class OutlineRole extends React.Component{

    render()
    {
        return (
            <div className="OutlineRole
                            flex maxw640:flex-wrap justify-i-center
                            maxw640:pad-t-20px">
                <div className="Outline
                                flex-basis-60 maxw640:flex-100p
                                pos-relative
                                pad-t-4p pad-b-4p pad-l-8p pad-r-4p
                                maxw640:pad-r-8p maxw640:pad-tb-10p
                                border-1px-solid-FAFAF2">
                    <h2 className="pad-b-10px">Project Outline</h2>
                    <p>{this.props.outline}</p>
                </div>
                <div className="Role
                                flex-basis-40 maxw640:flex-100p
                                pad-t-4p pad-b-4p pad-l-4p pad-r-8p
                                maxw640:pad-l-8p maxw640:pad-tb-10p
                                bac-col-FAFAF2">
                    <h2 className="pad-b-10px">My Role</h2>
                    <p>{this.props.role}</p>
                </div>
            </div>
        );
    }
}

export default OutlineRole;