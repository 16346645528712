import React from "react";

class H1 extends React.Component {
    render() {
        return (
            <div className="H1Heading width-100p text-al-center pad-t-4p maxw640:pad-tb-8p-4p pad-b-2p">
                <h1>{this.props.h1}</h1>
            </div>
        );
    }
}

export default H1;