import React from "react";

class H2 extends React.Component {
    render() {
        return (
            <div className="H2 width-100p text-al-left pad-t-4p maxw640:pad-tb-8p-4p  pad-l-8p pad-r-8p pad-b-2p">
                <h2>{this.props.h2}</h2>
            </div>
        );
    }
}

export default H2;