import ProjectBackButton from "../../components/projects/ProjectBackButton";
import TopHeroImage from "../../components/projects/TopHeroImage";
import GovHero from "../../assets/images/pgov/GovHero.png";
import Responsive from "../../assets/images/pgov/Responsive.png";
import EDMSPersona from "../../assets/images/pgov/EDMS_Persona.png";
import EDMSIA from "../../assets/images/pgov/Information_Architecture.png";
import LaptopGIF from "../../assets/images/pgov/Laptop.gif";
import TabletGIF from "../../assets/images/pgov/Tablet.gif";
import PhoneGIF from "../../assets/images/pgov/Phone.gif";
import HeadingProject from "../../components/projects/HeadingProject";
import OutlineRole from "../../components/projects/OutlineRole";
import ProjectInfo1 from "../../components/projects/ProjectInfo1";
import H1 from "../../components/projects/H1";
import NDAInfo from "../../components/projects/NDAInfo";
import ProjectInfo2 from "../../components/projects/ProjectInfo2";
import ProjectInfo4 from "../../components/projects/ProjectInfo4";
import ProjectInfo3 from "../../components/projects/ProjectInfo3";
import ProjectStats1 from "../../components/projects/ProjectStats1";
import ProjectStats2 from "../../components/projects/ProjectStats2";
import ProjectStats3 from "../../components/projects/ProjectStats3";
import React from "react";
import H2andParagraph from "../../components/projects/H2andParagraph";
import H2 from "../../components/projects/H2";
import ProblemSolution from "../../components/projects/ProblemSolution";
import EndProject from "../../components/projects/EndProject";
import LazyLoadImage from "../../components/LazyLoadImage";
import Project1Phase from "../../assets/images/pgov/Project1Phase.png";
import H3 from "../../components/projects/H3";
import InfoArch from "../../assets/images/pgov3/Information_Architecture.png";


function ProjectGov() {
    return (
        <div className="ProjectGov pad-t-70px maxw640:pad-t-60px">
            <ProjectBackButton/>
            <TopHeroImage src={GovHero}/>
            <div className="GovHead">
                <HeadingProject heading="Exotic Disease Management System for Public Body 2"/>
                <NDAInfo/>
                <OutlineRole outline="UX/UI for an Exotic Disease Management system, that tracks outbreaks,
                                      enables resource planning and coordination, and amalgamates relevant data
                                      inputted to the system and from other applicable systems. Users interact,
                                      and share pertinent data, through a specially designed autonomous workflow
                                      that leads them through the process of reporting, managing, containing and
                                      eventually eliminating an outbreak. Information on the outbreak as a whole,
                                      as well as details on individual outbreaks, and what's known about their
                                      relationship to one another, is automatically amalgamated and intuitively
                                      displayed. This system was built from scratch, prior to which the processes
                                      were paperbased."
                             role="I was the lead and sole UX researcher and designer for this project, working with the
                                   Project Owner and development team. I shared responsibility for user research
                                   with 2 BAs, whom I also collaborated with for prototype testing."/>
                <div className="StatsGov3 flex maxw640:flex-wrap align-i-center justify-i-center">
                    <ProjectStats1 number="6" name="User groups"/>
                    <ProjectStats2 number="1000+" name="Users"/>
                    <ProjectStats3 number="11" name="User journeys"/>
                </div>
            </div>
            <br/>
            <div className="GovBody">
                <H1 h1="Process"/>
                <div className="StatsGov flex maxw640:flex-wrap align-i-center justify-i-center">
                    <ProjectInfo1 head="Lean UX" sub="& Agile"/>
                    <ProjectInfo2 head="4 Week" sub="Sprints"/>
                    <ProjectInfo3 head="Qualitative" sub="Research"/>
                    <ProjectInfo4 head="Week 4" sub="Testing"/>
                </div>
                <H2andParagraph header="Discovery" text="A 9 month long discovery was conducted before the project
                began, of which I was involved in the final 2 months. During this 2 month period I researched and
                mapped 11 user journeys. This covered a user journey for &quot;Creating a Suspect Report&quot;,
                which is the starting point for every exotic disease case, a journey for editing a Suspect Report,
                a journey for each of the subsequent 8 workflow modules connected to a case, and a journey for
                viewing the summary dashboard for all cases. These were the user journeys I designed for
                throughout my involvement in the project."/>
                <div className="LeanUX width-100p text-al-left pad-t-4p maxw640:pad-tb-8p-4p
                                pad-l-8p pad-r-8p pad-b-2p bac-col-E6EFEE">
                    <h2>Lean UX Project Life Cycle with 1 Phase</h2>
                </div>
                <div className="ProjectPlan1Phase
                                pad-r-16p pad-l-16p
                                bac-col-E6EFEE">
                    <br/>
                    <br/>
                    <img src={Project1Phase}/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                <H2 h2="Persona Example"/>
                <div className="pad-r-16p pad-l-16p overflow-hid pad-tb-30px">
                    <LazyLoadImage src={EDMSPersona} alt="Persona Example Image"/>
                </div>
                <br/>
                <br/>
                <br/>
                <div className="H2 width-100p text-al-left
                                pad-t-4p maxw640:pad-tb-8p-4p pad-l-8p pad-r-8p pad-b-2p
                                bac-col-white">
                    <h2>Information Architecture</h2>
                </div>
                <div className="pad-r-8p pad-l-8p overflow-hid pad-tb-30px bac-col-white">
                    <LazyLoadImage src={EDMSIA} alt="Information Architecture Image"/>
                    <br/>
                    <br/>
                </div>
                <ProblemSolution problem="Through qualitative research, I discovered that a small percentage of
                                          users would not have access to the internet whilst creating a suspect
                                          report. At the time of research, this was not an issue as they were
                                          working via a paper based system with the forms scanned and uploaded
                                          to a document repository. However, this process was extremely inefficent,
                                          especially in an emergency situation where a large number of reports
                                          could be created for review, and hence the need for a digital system.
                                          These users would need to be catered for, opening up the research
                                          question: &quot;How best to ensure users could use the system offline
                                          while ensuring data was not lost and could be efficiently acquired and
                                          accessed?&quot;"
                                 solution="This was achieved by displaying the system status, either
                                           online or offline, in a highly visible and always accessible position
                                           on the UI. Feedback on whether or not the information sync between
                                           device and online system was up-to-date, and when the last system sync
                                           was completed, was also made readily available. These solutions were
                                           iteratively user tested to achieve the best outcome."/>
                <br/>
                <H2 h2="Multi-Device Design"/>
                <div className="ResponsiveImage
                                mar-r-8p mar-l-8p overflow-hid">
                    <LazyLoadImage src={Responsive} alt="Application displayed on pc, tablet
                    and phone"/>
                </div>
                <div className="LaptopGIF
                                pad-r-8p pad-l-8p overflow-hid pad-tb-30px bac-col-E6EFEE">
                    <img className="mar-auto" src={LaptopGIF} alt="GIF of system on laptop"/>
                </div>
                <div className="flex maxw640:flex-wrap align-i-center">
                    <div className="TabletGIF
                                    pad-l-8p overflow-hid pad-tb-30px bac-col-FAFAF2 flex-basis-50">
                        <img className="mar-auto" src={TabletGIF} alt="GIF of system on tablet"/>
                    </div>
                    <div className="PhoneGIF
                                    pad-r-8p overflow-hid pad-tb-30px bac-col-E9ECEA flex-basis-50">
                        <img className="mar-auto" src={PhoneGIF} alt="GIF of system on phone"/>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <EndProject />
        </div>
    );
}

export default ProjectGov;