import React from 'react';
import LazyLoadImage from "../LazyLoadImage";

class TwoImages extends React.Component {
    render() {
        return (
            <div className="TwoImages
                            flex maxw640:flex-wrap
                            mar-l-8p mar-r-8p
                            border-5px-solid-E9ECEA bac-col-white overflow-hid">
                <div className="Image1 pad-2p mar-auto">
                    <LazyLoadImage src={this.props.src1}/>
                </div>
                <div className="Image2 pad-2p mar-auto">
                    <LazyLoadImage src={this.props.src2}/>
                </div>
            </div>
        );
    }
}

export default TwoImages;