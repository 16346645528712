import React from "react";


function FlexTable() {
    return (
        <div className="flexTableContainer width-50p maxw640:width-100p maxw860:width-80p mar-auto">
            <div className="headingContainer
                            flex text-al-center text-ver-align-mid line-height-3Rem height-3rem">
                <div className="flex-basis-25"/>
                <div className="flex-basis-50 border-2px-solid-F1F9F9 bac-col-EDE8EA">Results (Success Rate)</div>
            </div>
            <div className="subHeadingContainer
                            flex text-al-center text-ver-align-mid line-height-3Rem text-wrap height-3rem">
                <div className="flex-basis-25"/>
                <div className="flex-basis-25 border-2px-solid-F1F9F9 bac-col-E9ECEA">Control R1</div>
                <div className="flex-basis-25 border-2px-solid-F1F9F9 bac-col-E9ECEA">Experimental R2</div>
                <div className="flex-basis-25 border-2px-solid-F1F9F9 bac-col-E9ECEA">% diff</div>
            </div>
            <div className="table flex">
                <div className="col1 flex-basis-25">
                    <div className="col1Container flex flex-dir-col bac-col-E9ECEA
                                    text-al-center text-ver-align-mid line-height-3Rem">
                        <div className="height-3rem border-2px-solid-F1F9F9">2 exp</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">3 exp</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">4 exp</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">5 exp</div>
                    </div>
                </div>
                <div className="col2 flex-basis-25">
                    <div className="col2Container flex flex-dir-col bac-col-E6EFEE
                                    text-al-center text-ver-align-mid line-height-3Rem ">
                        <div className="height-3rem border-2px-solid-F1F9F9">20%</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">0%</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">0%</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">0%</div>
                    </div>
                </div>
                <div className="col3 flex-basis-25">
                    <div className="col3Container flex flex-dir-col bac-col-FAFAF2
                                    text-al-center text-ver-align-mid line-height-3Rem ">
                        <div className="height-3rem border-2px-solid-F1F9F9">100%</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">80%</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">20%</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">0%</div>
                    </div>
                </div>
                <div className="col4 flex-basis-25">
                    <div className="col4Container flex flex-dir-col bac-col-E9ECEA
                                    text-al-center text-ver-align-mid line-height-3Rem ">
                        <div className="height-3rem border-2px-solid-F1F9F9">80%</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">80%</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">20%</div>
                        <div className="height-3rem border-2px-solid-F1F9F9">0%</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FlexTable;