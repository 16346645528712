import About from "../components/aboutcontact/About";
import Contact from "../components/aboutcontact/Contact";
import Footer from "../parts/Footer";
import React from "react";

function AboutContact() {
    return (
        <div>
            <div className="AboutContact
                            flex maxw768:flex-wrap justify-i-center align-i-center
                            pad-t-8p maxw768:pad-t-12p
                            mar-l-8p mar-r-8p maxw768:mar-lr-20px">
                <About/>
                <Contact/>
            </div>
            <div className="FooterWrap mar-l-8p mar-r-8p">
                <Footer/>
            </div>
        </div>
    );
}

export default AboutContact;