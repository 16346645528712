import React from "react";

class Footer extends React.Component {
    constructor() {
        super();

        var today = new Date(),
            year = today.getFullYear();

        this.state = {
            year: year
        };
    }

    render() {
        return (
            <div className="Footer z-99
                            pad-t-4p maxw768:mar-lr-10px maxw440:mar-lr-5px">
                <hr className="box-sizing-content overflow-vis height-1px border-none bac-rgba-115-113-132-0p2"/>
                <div className="Links pad-t-10px pad-b-20px a-color-2C">
                    <div className="font-fam-con-light font-size-larger font-weight-500">
                        © {this.state.year} &nbsp;&nbsp;·&nbsp;&nbsp;
                        <a href="mailto:rchlelder@gmail.com?subject=👋 Hello"
                           className="sc-1kqwte8-1 dgOnnR">Email</a> &nbsp;&nbsp;·&nbsp;&nbsp;
                        <a href="https://www.linkedin.com/in/rachaelelder/" className="sc-1kqwte8-1 dgOnnR">LinkedIn</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;