import React from 'react';

class HeadingProject extends React.Component{

    render()
    {
        return (
            <div className="HeadingProject
                            pad-t-4p pad-b-3p pad-l-8p pad-r-8p
                            font-size-2em maxw440:font-size-1p6em
                            text-al-center color-1A3F45">
                {this.props.heading}
            </div>
        );
    }
}

export default HeadingProject;