import React from 'react';
import LazyLoadImage from "../LazyLoadImage";

class TopHeroImage extends React.Component {
    render() {
        return (
            <div className="TopHeroImage width-100p pad-t-49px overflow-hid">
                <LazyLoadImage src={this.props.src}/>
            </div>
        );
    }
}

export default TopHeroImage;